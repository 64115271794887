import React from "react";
import classNames from "classnames";

import { ReactComponent as Check } from "../../../assets/icons/check-circle-regular.svg";
import { ReactComponent as Worng } from "../../../assets/icons/exclamation-circle-regular.svg";
import { ReactComponent as Pause } from "../../../assets/icons/pause-circle-regular.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right-regular.svg";
import Fonts from "../../General/Fonts/Fonts";
import { OrderInfo } from "../../../types/responseTypes";
import { hungerConsumerPlatformURL } from "../../Main";

interface Props extends Pick<OrderInfo, "prePayStatus"> {
  groupDomain: string;
  orderShortId: string;
}

const PrePayStatusBar: React.FC<Props> = ({
  prePayStatus,
  groupDomain,
  orderShortId,
}) => {
  if (!prePayStatus) return null;

  const Icon = (() => {
    if (prePayStatus === "CHECKED") {
      return Check;
    } else if (prePayStatus === "REPORT_PAYED") {
      return Pause;
    } else {
      return Worng;
    }
  })();

  const text = (() => {
    if (prePayStatus === "CHECKED") {
      return "商家已確認";
    } else if (prePayStatus === "REPORT_PAYED") {
      return "等待商家確認";
    } else {
      return `請支付定金`;
    }
  })();

  return (
    <a
      href={`${hungerConsumerPlatformURL}/g/${groupDomain}/prepay/${orderShortId}`}
      className={classNames("flex-1", "flex", "justify-center", "items-center")}
    >
      <Fonts
        fontSize="body"
        className={classNames(
          "flex",
          "items-center",
          "w-full",
          "h-11",
          "rounded-xs",
          "px-4",
          "py-3",
          "text-white",
          {
            "bg-green": prePayStatus === "CHECKED",
            "bg-red": prePayStatus === "WAITING",
            "bg-blue": prePayStatus === "REPORT_PAYED",
          }
        )}
      >
        <Icon className={classNames("w-4", "h-4", "mr-2")} />
        <span>{text}</span>
        <ChevronRight className={classNames("ml-auto", "w-1.5")} />
      </Fonts>
    </a>
  );
};

export default PrePayStatusBar;
