import React from "react";

const NoMatch = () => {
	return (
		<div className="no_match container">
			<img
				className="no_match__img"
				src={require("../../../assets/materials/hun-4-1-404-img.png")}
				srcSet={[
					`${require("../../../assets/materials/hun-4-1-404-img.png")} 1x`,
					`${require("../../../assets/materials/hun-4-1-404-img@2x.png")} 2x`,
					`${require("../../../assets/materials/hun-4-1-404-img@3x.png")} 3x`,
				].join(",")}
				alt="Tawin Flag"
			/>
			<h3 className="no_match__heading">抱歉，您訪問的頁面出錯了</h3>
			<p className="no_match__text body">您可能輸錯了網址，或該網頁已不存在</p>
		</div>
	);
};

export default NoMatch;
