import React from "react";
import Modal from "../General/Modal/Modal";

type OwnProps = {
  show: boolean;
  close: () => void;
  onConfirm: () => void;
};

const CancelConfirmModal: React.FC<OwnProps> = ({ close, onConfirm, show }) => {
  if (!show) return null;

  return (
    <Modal>
      <div className="cancel">
        <div className="cancel__content">
          <h3 className="cancel__heading">提示</h3>
          <p className="cancel__text body">確定要取消預約？</p>
        </div>
        <div className="cancel__btn">
          <button onClick={close} className="button cancel__btn--1">
            取消
          </button>
          <button onClick={onConfirm} className="button cancel__btn--2">
            確定
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelConfirmModal;
