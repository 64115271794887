import React from "react";
import classNames from "classnames";
import Tag, { TagComponentProps } from "../Tag/Tag";

interface Props {
	direction?: "row" | "col";
	justify?: "start" | "end" | "center" | "between" | "around" | "evenly";
	align?: "start" | "end" | "center" | "baseline" | "stretch";
	expand?: boolean;
	className?: string;
}

const Flexbox: React.FC<Props & TagComponentProps> = ({
	as = "div",
	direction = "row",
	justify = "center",
	align = "center",
	children,
	className,
	expand,
	...props
}) => {
	return (
		<Tag
			className={classNames(
				"flex",
				{
					"flex-row": direction === "row",
					"flex-col": direction === "col",
				},
				{
					"justify-start": justify === "start",
					"justify-end": justify === "end",
					"justify-center": justify === "center",
					"justify-between": justify === "between",
					"justify-around": justify === "around",
					"justify-evenly": justify === "evenly",
				},
				{
					"items-start": align === "start",
					"items-end": align === "end",
					"items-center": align === "center",
					"items-baseline": align === "baseline",
					"items-stretch": align === "stretch",
				},
				{ "flex-1": expand },
				className
			)}
			as={as}
			{...props}
		>
			{children}
		</Tag>
	);
};

export default Flexbox;
