import {
  CityType,
  CountryType,
  DistrictType,
  StaticOptionTypes,
} from "./static.option.types";

export type Key = {
  ["key"]: string;
  ["label"]: string;
  ["active"]: boolean;
};

type EmptyType = (placeholder: string) => Key[];

export const emptyType: EmptyType = (placeholder: string) => [
  { key: "", label: `請選擇${placeholder}`, active: true },
];

export const getStaticOptionLabel = (
  staticOptionTypes: StaticOptionTypes,
  key: string
): string => {
  let label: string = "";
  staticOptionType[staticOptionTypes].forEach((option: Key) => {
    label = option.key === key ? option.label : label;
  });
  return label;
};

export type StaticOptionType = {
  serviceStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  resourceOnlineReservationStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  groupUserStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  orderConnectionStatus: [
    {
      key: "NONE";
      label: "未聯絡";
      active: true;
    },
    {
      key: "CONNECTED";
      label: "已聯絡";
      active: true;
    }
  ];
  storeStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    },
    {
      key: "DRAFT";
      label: "未開通";
      active: true;
    }
  ];
  planPaymentPeriod: [
    {
      key: "MONTH";
      label: "月繳";
      active: true;
    },
    {
      key: "YEAR";
      label: "年繳";
      active: true;
    }
  ];
  groupStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  resourceQuestionStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  resourceStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  clerkStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  resourceOnlineReservationEndTime: [
    {
      key: "NONE";
      label: "不限制（0分鐘）";
      active: true;
    },
    {
      key: "MIN_10";
      label: "10分鐘";
      active: true;
    },
    {
      key: "MIN_30";
      label: "30分鐘";
      active: true;
    },
    {
      key: "MIN_60";
      label: "60分鐘";
      active: true;
    },
    {
      key: "MIN_180";
      label: "180分鐘";
      active: true;
    },
    {
      key: "DAY_1";
      label: "1天";
      active: true;
    },
    {
      key: "DAY_3";
      label: "3天";
      active: true;
    },
    {
      key: "WEEK_1";
      label: "1週";
      active: true;
    }
  ];
  orderStatus: [
    {
      key: "DRAFT";
      label: "草稿";
      active: true;
    },
    {
      key: "WAITING";
      label: "尚未報到";
      active: true;
    },
    {
      key: "CHECKED_IN";
      label: "已報到";
      active: true;
    },
    {
      key: "CANCEL";
      label: "取消";
      active: true;
    },
    {
      key: "CONSUMER_CANCEL";
      label: "顧客取消";
      active: true;
    },
    {
      key: "UNPAID_CANCEL";
      label: "預付款未付取消";
      active: true;
    },
    {
      key: "NO_SHOW";
      label: "No Show";
      active: true;
    }
  ];
  categoryStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  resourceQuestionOptionRequireType: [
    {
      key: "OPTIONAL";
      label: "選填";
      active: true;
    },
    {
      key: "REQUIRED";
      label: "必填*";
      active: true;
    }
  ];
  deviceStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    },
    {
      key: "DRAFT";
      label: "未開通";
      active: true;
    }
  ];
  consumerMembership: [
    {
      key: "NOT_MEMBER";
      label: "非會員";
      active: true;
    },
    {
      key: "MEMBER";
      label: "會員";
      active: true;
    }
  ];
  planStatus: [
    {
      key: "ON";
      label: "開啟";
      active: true;
    },
    {
      key: "OFF";
      label: "關閉";
      active: true;
    }
  ];
  resourceQuestionOptionType: [
    {
      key: "SINGLE";
      label: "單選";
      active: true;
    },
    {
      key: "MULTIPLE";
      label: "複選";
      active: true;
    }
  ];
  orderCreationType: [
    {
      key: "PAD";
      label: "平板建立";
      active: true;
    },
    {
      key: "ONLINE";
      label: "網路預約";
      active: true;
    }
  ];
  resourceOnlineReservationStartTime: [
    {
      key: "NONE";
      label: "不限制";
      active: true;
    },
    {
      key: "MONTH_6";
      label: "6個月";
      active: true;
    },
    {
      key: "MONTH_3";
      label: "3個月";
      active: true;
    },
    {
      key: "MONTH_2";
      label: "2個月";
      active: true;
    },
    {
      key: "MONTH_1";
      label: "1個月";
      active: true;
    },
    {
      key: "WEEK_2";
      label: "2週";
      active: true;
    },
    {
      key: "WEEK_1";
      label: "1週";
      active: true;
    },
    {
      key: "DAY_3";
      label: "3天";
      active: true;
    },
    {
      key: "DAY_1";
      label: "1天";
      active: true;
    }
  ];
  questionType: [
    {
      key: "SINGLE";
      label: "單選";
      active: true;
    },
    {
      key: "MULTIPLE";
      label: "多選";
      active: true;
    }
  ];
  gender: [
    {
      key: "MALE";
      label: "男";
      active: true;
    },
    {
      key: "FEMALE";
      label: "女";
      active: true;
    }
  ];
  // COUNTRY
};

export const staticOptionType: StaticOptionType = {
  serviceStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  resourceOnlineReservationStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  groupUserStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  orderConnectionStatus: [
    {
      key: "NONE",
      label: "未聯絡",
      active: true,
    },
    {
      key: "CONNECTED",
      label: "已聯絡",
      active: true,
    },
  ],
  storeStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
    {
      key: "DRAFT",
      label: "未開通",
      active: true,
    },
  ],
  planPaymentPeriod: [
    {
      key: "MONTH",
      label: "月繳",
      active: true,
    },
    {
      key: "YEAR",
      label: "年繳",
      active: true,
    },
  ],
  groupStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  resourceQuestionStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  resourceStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  clerkStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  resourceOnlineReservationEndTime: [
    {
      key: "NONE",
      label: "不限制（0分鐘）",
      active: true,
    },
    {
      key: "MIN_10",
      label: "10分鐘",
      active: true,
    },
    {
      key: "MIN_30",
      label: "30分鐘",
      active: true,
    },
    {
      key: "MIN_60",
      label: "60分鐘",
      active: true,
    },
    {
      key: "MIN_180",
      label: "180分鐘",
      active: true,
    },
    {
      key: "DAY_1",
      label: "1天",
      active: true,
    },
    {
      key: "DAY_3",
      label: "3天",
      active: true,
    },
    {
      key: "WEEK_1",
      label: "1週",
      active: true,
    },
  ],
  orderStatus: [
    {
      key: "DRAFT",
      label: "草稿",
      active: true,
    },
    {
      key: "WAITING",
      label: "尚未報到",
      active: true,
    },
    {
      key: "CHECKED_IN",
      label: "已報到",
      active: true,
    },
    {
      key: "CANCEL",
      label: "取消",
      active: true,
    },
    {
      key: "CONSUMER_CANCEL",
      label: "顧客取消",
      active: true,
    },
    {
      key: "UNPAID_CANCEL",
      label: "預付款未付取消",
      active: true,
    },
    {
      key: "NO_SHOW",
      label: "No Show",
      active: true,
    },
  ],
  categoryStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  resourceQuestionOptionRequireType: [
    {
      key: "OPTIONAL",
      label: "選填",
      active: true,
    },
    {
      key: "REQUIRED",
      label: "必填*",
      active: true,
    },
  ],
  deviceStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
    {
      key: "DRAFT",
      label: "未開通",
      active: true,
    },
  ],
  consumerMembership: [
    {
      key: "NOT_MEMBER",
      label: "非會員",
      active: true,
    },
    {
      key: "MEMBER",
      label: "會員",
      active: true,
    },
  ],
  planStatus: [
    {
      key: "ON",
      label: "開啟",
      active: true,
    },
    {
      key: "OFF",
      label: "關閉",
      active: true,
    },
  ],
  resourceQuestionOptionType: [
    {
      key: "SINGLE",
      label: "單選",
      active: true,
    },
    {
      key: "MULTIPLE",
      label: "複選",
      active: true,
    },
  ],
  orderCreationType: [
    {
      key: "PAD",
      label: "平板建立",
      active: true,
    },
    {
      key: "ONLINE",
      label: "網路預約",
      active: true,
    },
  ],
  resourceOnlineReservationStartTime: [
    {
      key: "NONE",
      label: "不限制",
      active: true,
    },
    {
      key: "MONTH_6",
      label: "6個月",
      active: true,
    },
    {
      key: "MONTH_3",
      label: "3個月",
      active: true,
    },
    {
      key: "MONTH_2",
      label: "2個月",
      active: true,
    },
    {
      key: "MONTH_1",
      label: "1個月",
      active: true,
    },
    {
      key: "WEEK_2",
      label: "2週",
      active: true,
    },
    {
      key: "WEEK_1",
      label: "1週",
      active: true,
    },
    {
      key: "DAY_3",
      label: "3天",
      active: true,
    },
    {
      key: "DAY_1",
      label: "1天",
      active: true,
    },
  ],
  questionType: [
    {
      key: "SINGLE",
      label: "單選",
      active: true,
    },
    {
      key: "MULTIPLE",
      label: "多選",
      active: true,
    },
  ],
  gender: [
    {
      key: "MALE",
      label: "男",
      active: true,
    },
    {
      key: "FEMALE",
      label: "女",
      active: true,
    },
  ],
  // COUNTRY
};

export const countryType: CountryType = [
  { key: "TW", label: "台灣", active: true },
];

export const cityType: CityType = {
  TW: [
    { key: "KLU", label: "基隆市", active: true },
    { key: "TPH", label: "新北市", active: true },
    { key: "TPE", label: "臺北市", active: true },
    { key: "TYC", label: "桃園市", active: true },
    { key: "HSH", label: "新竹縣", active: true },
    { key: "HSC", label: "新竹市", active: true },
    { key: "MAL", label: "苗栗縣", active: true },
    { key: "TXG", label: "臺中市", active: true },
    { key: "CWH", label: "彰化縣", active: true },
    { key: "NTO", label: "南投縣", active: true },
    { key: "YLH", label: "雲林縣", active: true },
    { key: "CHY", label: "嘉義縣", active: true },
    { key: "CYI", label: "嘉義市", active: true },
    { key: "TNN", label: "臺南市", active: true },
    { key: "KHH", label: "高雄市", active: true },
    { key: "IUH", label: "屏東縣", active: true },
    { key: "ILN", label: "宜蘭縣", active: true },
    { key: "HWA", label: "花蓮縣", active: true },
    { key: "TTT", label: "臺東縣", active: true },
    { key: "PEH", label: "澎湖縣", active: true },
    { key: "KMN", label: "金門縣", active: true },
    { key: "LNN", label: "連江縣", active: true },
  ],
};

export const districtType: DistrictType = {
  KLU: [
    { key: "Zhongzheng", label: "中正區", active: true },
    { key: "Qidu", label: "七堵區", active: true },
    { key: "Nuannuan", label: "暖暖區", active: true },
    { key: "Ren’ai", label: "仁愛區", active: true },
    { key: "Zhongshan", label: "中山區", active: true },
    { key: "Anle", label: "安樂區", active: true },
    { key: "Xinyi", label: "信義區", active: true },
  ],
  TPH: [
    { key: "Banqiao", label: "板橋區", active: true },
    { key: "Sanchong", label: "三重區", active: true },
    { key: "Zhonghe", label: "中和區", active: true },
    { key: "Yonghe", label: "永和區", active: true },
    { key: "Xinzhuang", label: "新莊區", active: true },
    { key: "Xindian", label: "新店區", active: true },
    { key: "Tucheng", label: "土城區", active: true },
    { key: "Luzhou", label: "蘆洲區", active: true },
    { key: "Xizhi", label: "汐止區", active: true },
    { key: "Shulin", label: "樹林區", active: true },
    { key: "Tamsui", label: "淡水區", active: true },
    { key: "Yingge", label: "鶯歌區", active: true },
    { key: "Sanxia", label: "三峽區", active: true },
    { key: "Ruifang", label: "瑞芳區", active: true },
    { key: "Wugu", label: "五股區", active: true },
    { key: "Taishan", label: "泰山區", active: true },
    { key: "Linkou", label: "林口區", active: true },
    { key: "Shenkeng", label: "深坑區", active: true },
    { key: "Shiding", label: "石碇區", active: true },
    { key: "Pinglin", label: "坪林區", active: true },
    { key: "Sanzhi", label: "三芝區", active: true },
    { key: "Shimen", label: "石門區", active: true },
    { key: "Bali", label: "八里區", active: true },
    { key: "Pingxi", label: "平溪區", active: true },
    { key: "Shuangxi", label: "雙溪區", active: true },
    { key: "Gongliao", label: "貢寮區", active: true },
    { key: "Jinshan", label: "金山區", active: true },
    { key: "Wanli", label: "萬里區", active: true },
    { key: "Wulai", label: "烏來區", active: true },
  ],
  TPE: [
    { key: "Songshan", label: "松山區", active: true },
    { key: "Da’an", label: "大安區", active: true },
    { key: "Zhongzheng", label: "中正區", active: true },
    { key: "Wanhua", label: "萬華區", active: true },
    { key: "Datong", label: "大同區", active: true },
    { key: "Zhongshan", label: "中山區", active: true },
    { key: "Wenshan", label: "文山區", active: true },
    { key: "Nangang", label: "南港區", active: true },
    { key: "Neihu", label: "內湖區", active: true },
    { key: "Shilin", label: "士林區", active: true },
    { key: "Beitou", label: "北投區", active: true },
    { key: "Xinyi", label: "信義區", active: true },
  ],
  TYC: [
    { key: "Taoyuan", label: "桃園區", active: true },
    { key: "Zhongli", label: "中壢區", active: true },
    { key: "Daxi", label: "大溪區", active: true },
    { key: "Yangmei", label: "楊梅區", active: true },
    { key: "Luzhu", label: "蘆竹區", active: true },
    { key: "Dayuan", label: "大園區", active: true },
    { key: "Guishan", label: "龜山區", active: true },
    { key: "Bade", label: "八德區", active: true },
    { key: "Longtan", label: "龍潭區", active: true },
    { key: "Pingzhen", label: "平鎮區", active: true },
    { key: "Xinwu", label: "新屋區", active: true },
    { key: "Guanyin", label: "觀音區", active: true },
    { key: "Fuxing", label: "復興區", active: true },
  ],
  HSH: [
    { key: "ZhubeiCity", label: "竹北市", active: true },
    { key: "Zhudong", label: "竹東鎮", active: true },
    { key: "Xinpu", label: "新埔鎮", active: true },
    { key: "Hukou", label: "湖口鄉", active: true },
    { key: "Xinfeng", label: "新豐鄉", active: true },
    { key: "Qionglin", label: "芎林鄉", active: true },
    { key: "Hengshan", label: "橫山鄉", active: true },
    { key: "Beipu", label: "北埔鄉", active: true },
    { key: "Baoshan", label: "寶山鄉", active: true },
    { key: "Guanxi", label: "關西鎮", active: true },
    { key: "Emei", label: "峨眉鄉", active: true },
    { key: "Jianshi", label: "尖石鄉", active: true },
    { key: "Wufeng", label: "五峰鄉", active: true },
  ],
  HSC: [
    { key: "East", label: "東區", active: true },
    { key: "North", label: "北區", active: true },
    { key: "Xiangshan", label: "香山區", active: true },
  ],
  MAL: [
    { key: "MiaoliCity", label: "苗栗市", active: true },
    { key: "Yuanli", label: "苑裡鎮", active: true },
    { key: "Tongxiao", label: "通霄鎮", active: true },
    { key: "Zhunan", label: "竹南鎮", active: true },
    { key: "Toufen", label: "頭份鎮", active: true },
    { key: "Houlong", label: "後龍鎮", active: true },
    { key: "Zhuolan", label: "卓蘭鎮", active: true },
    { key: "Dahu", label: "大湖鄉", active: true },
    { key: "Gongguan", label: "公館鄉", active: true },
    { key: "Tongluo", label: "銅鑼鄉", active: true },
    { key: "Nanzhuang", label: "南庄鄉", active: true },
    { key: "Touwu", label: "頭屋鄉", active: true },
    { key: "Sanyi", label: "三義鄉", active: true },
    { key: "Xihu", label: "西湖鄉", active: true },
    { key: "Zaoqiao", label: "造橋鄉", active: true },
    { key: "Sanwan", label: "三灣鄉", active: true },
    { key: "Shitan", label: "獅潭鄉", active: true },
    { key: "Tai’an", label: "泰安鄉", active: true },
  ],
  TXG: [
    { key: "Central", label: "中區", active: true },
    { key: "East", label: "東區", active: true },
    { key: "South", label: "南區", active: true },
    { key: "West", label: "西區", active: true },
    { key: "North", label: "北區", active: true },
    { key: "Xitun", label: "西屯區", active: true },
    { key: "Nantun", label: "南屯區", active: true },
    { key: "Beitun", label: "北屯區", active: true },
    { key: "Fengyuan", label: "豐原區", active: true },
    { key: "Dongshi", label: "東勢區", active: true },
    { key: "Dajia", label: "大甲區", active: true },
    { key: "Qingshui", label: "清水區", active: true },
    { key: "Shalu", label: "沙鹿區", active: true },
    { key: "Wuqi", label: "梧棲區", active: true },
    { key: "Houli", label: "后里區", active: true },
    { key: "Shengang", label: "神岡區", active: true },
    { key: "Tanzi", label: "潭子區", active: true },
    { key: "Daya", label: "大雅區", active: true },
    { key: "Xinshe", label: "新社區", active: true },
    { key: "Shigang", label: "石岡區", active: true },
    { key: "Waipu", label: "外埔區", active: true },
    { key: "Da’an", label: "大安區", active: true },
    { key: "Wuri", label: "烏日區", active: true },
    { key: "Dadu", label: "大肚區", active: true },
    { key: "Longjing", label: "龍井區", active: true },
    { key: "Wufeng", label: "霧峰區", active: true },
    { key: "Taiping", label: "太平區", active: true },
    { key: "Dali", label: "大里區", active: true },
    { key: "Heping", label: "和平區", active: true },
  ],
  CWH: [
    { key: "ChanghuaCity", label: "彰化市", active: true },
    { key: "Lukang", label: "鹿港鎮", active: true },
    { key: "Hemei", label: "和美鎮", active: true },
    { key: "Xianxi", label: "線西鄉", active: true },
    { key: "Shengang", label: "伸港鄉", active: true },
    { key: "Fuxing", label: "福興鄉", active: true },
    { key: "Xiushui", label: "秀水鄉", active: true },
    { key: "Huatan", label: "花壇鄉", active: true },
    { key: "Fenyuan", label: "芬園鄉", active: true },
    { key: "Yuanlin", label: "員林鎮", active: true },
    { key: "Xihu", label: "溪湖鎮", active: true },
    { key: "Tianzhong", label: "田中鎮", active: true },
    { key: "Dacun", label: "大村鄉", active: true },
    { key: "Puyan", label: "埔鹽鄉", active: true },
    { key: "Puxin", label: "埔心鄉", active: true },
    { key: "Yongjing", label: "永靖鄉", active: true },
    { key: "Shetou", label: "社頭鄉", active: true },
    { key: "Ershui", label: "二水鄉", active: true },
    { key: "Beidou", label: "北斗鎮", active: true },
    { key: "Erlin", label: "二林鎮", active: true },
    { key: "Tianwei", label: "田尾鄉", active: true },
    { key: "Pitou", label: "埤頭鄉", active: true },
    { key: "Fangyuan", label: "芳苑鄉", active: true },
    { key: "Dacheng", label: "大城鄉", active: true },
    { key: "Zhutang", label: "竹塘鄉", active: true },
    { key: "Xizhou", label: "溪州鄉", active: true },
  ],
  NTO: [
    { key: "NantouCity", label: "南投市", active: true },
    { key: "Puli", label: "埔里鎮", active: true },
    { key: "Caotun", label: "草屯鎮", active: true },
    { key: "Zhushan", label: "竹山鎮", active: true },
    { key: "Jiji", label: "集集鎮", active: true },
    { key: "Mingjian", label: "名間鄉", active: true },
    { key: "Lugu", label: "鹿谷鄉", active: true },
    { key: "Zhongliao", label: "中寮鄉", active: true },
    { key: "Yuchi", label: "魚池鄉", active: true },
    { key: "Guoxing", label: "國姓鄉", active: true },
    { key: "Shuili", label: "水里鄉", active: true },
    { key: "Xinyi", label: "信義鄉", active: true },
    { key: "Ren’ai", label: "仁愛鄉", active: true },
  ],
  YLH: [
    { key: "DouliuCity", label: "斗六市", active: true },
    { key: "Dounan", label: "斗南鎮", active: true },
    { key: "Huwei", label: "虎尾鎮", active: true },
    { key: "Xiluo", label: "西螺鎮", active: true },
    { key: "Tuku", label: "土庫鎮", active: true },
    { key: "Beigang", label: "北港鎮", active: true },
    { key: "Gukeng", label: "古坑鄉", active: true },
    { key: "Dapi", label: "大埤鄉", active: true },
    { key: "Citong", label: "莿桐鄉", active: true },
    { key: "Linnei", label: "林內鄉", active: true },
    { key: "Erlun", label: "二崙鄉", active: true },
    { key: "Lunbei", label: "崙背鄉", active: true },
    { key: "Mailiao", label: "麥寮鄉", active: true },
    { key: "Dongshi", label: "東勢鄉", active: true },
    { key: "Baozhong", label: "褒忠鄉", active: true },
    { key: "Taixi", label: "臺西鄉", active: true },
    { key: "Yuanchang", label: "元長鄉", active: true },
    { key: "Sihu", label: "四湖鄉", active: true },
    { key: "Kouhu", label: "口湖鄉", active: true },
    { key: "Shuilin", label: "水林鄉", active: true },
  ],
  CHY: [
    { key: "TaibaoCity", label: "太保市", active: true },
    { key: "PuziCity", label: "朴子市", active: true },
    { key: "Budai", label: "布袋鎮", active: true },
    { key: "Dalin", label: "大林鎮", active: true },
    { key: "Minxiong", label: "民雄鄉", active: true },
    { key: "Xikou", label: "溪口鄉", active: true },
    { key: "Xingang", label: "新港鄉", active: true },
    { key: "Liujiao", label: "六腳鄉", active: true },
    { key: "Dongshi", label: "東石鄉", active: true },
    { key: "Yizhu", label: "義竹鄉", active: true },
    { key: "Lucao", label: "鹿草鄉", active: true },
    { key: "Shuishang", label: "水上鄉", active: true },
    { key: "Zhongpu", label: "中埔鄉", active: true },
    { key: "Zhuqi", label: "竹崎鄉", active: true },
    { key: "Meishan", label: "梅山鄉", active: true },
    { key: "Fanlu", label: "番路鄉", active: true },
    { key: "Dapu", label: "大埔鄉", active: true },
    { key: "Alishan", label: "阿里山鄉", active: true },
  ],
  CYI: [
    { key: "East", label: "東區", active: true },
    { key: "West", label: "西區", active: true },
  ],
  TNN: [
    { key: "East", label: "東區", active: true },
    { key: "South", label: "南區", active: true },
    { key: "North", label: "北區", active: true },
    { key: "Annan", label: "安南區", active: true },
    { key: "Anping", label: "安平區", active: true },
    { key: "West Central", label: "中西區", active: true },
    { key: "Xinying", label: "新營區", active: true },
    { key: "Yanshui", label: "鹽水區", active: true },
    { key: "Liuying", label: "柳營區", active: true },
    { key: "Baihe", label: "白河區", active: true },
    { key: "Houbi", label: "後壁區", active: true },
    { key: "Dongshan", label: "東山區", active: true },
    { key: "Madou", label: "麻豆區", active: true },
    { key: "Xiaying", label: "下營區", active: true },
    { key: "Liujia", label: "六甲區", active: true },
    { key: "Guantian", label: "官田區", active: true },
    { key: "Danei", label: "大內區", active: true },
    { key: "Jiali", label: "佳里區", active: true },
    { key: "Xigang", label: "西港區", active: true },
    { key: "Qigu", label: "七股區", active: true },
    { key: "Jiangjun", label: "將軍區", active: true },
    { key: "Beimen", label: "北門區", active: true },
    { key: "Xuejia", label: "學甲區", active: true },
    { key: "Xinhua", label: "新化區", active: true },
    { key: "Shanhua", label: "善化區", active: true },
    { key: "Xinshi", label: "新市區", active: true },
    { key: "Anding", label: "安定區", active: true },
    { key: "Shanshang", label: "山上區", active: true },
    { key: "Zuozhen", label: "左鎮區", active: true },
    { key: "Rende", label: "仁德區", active: true },
    { key: "Guiren", label: "歸仁區", active: true },
    { key: "Guanmiao", label: "關廟區", active: true },
    { key: "Longqi", label: "龍崎區", active: true },
    { key: "Yujing", label: "玉井區", active: true },
    { key: "Nanxi", label: "楠西區", active: true },
    { key: "Nanhua", label: "南化區", active: true },
    { key: "Yongkang", label: "永康區", active: true },
  ],
  KHH: [
    { key: "Yancheng", label: "鹽埕區", active: true },
    { key: "Gushan", label: "鼓山區", active: true },
    { key: "Zuoying", label: "左營區", active: true },
    { key: "Nanzi", label: "楠梓區", active: true },
    { key: "Sanmin", label: "三民區", active: true },
    { key: "Xinxing", label: "新興區", active: true },
    { key: "Qianjin", label: "前金區", active: true },
    { key: "Lingya", label: "苓雅區", active: true },
    { key: "Qianzhen", label: "前鎮區", active: true },
    { key: "Qijin", label: "旗津區", active: true },
    { key: "Xiaogang", label: "小港區", active: true },
    { key: "Fengshan", label: "鳳山區", active: true },
    { key: "Linyuan", label: "林園區", active: true },
    { key: "Daliao", label: "大寮區", active: true },
    { key: "Dashu", label: "大樹區", active: true },
    { key: "Dashe", label: "大社區", active: true },
    { key: "Renwu", label: "仁武區", active: true },
    { key: "Niaosong", label: "鳥松區", active: true },
    { key: "Gangshan", label: "岡山區", active: true },
    { key: "Qiaotou", label: "橋頭區", active: true },
    { key: "Yanchao", label: "燕巢區", active: true },
    { key: "Tianliao", label: "田寮區", active: true },
    { key: "Alian", label: "阿蓮區", active: true },
    { key: "Luzhu", label: "路竹區", active: true },
    { key: "Hunei", label: "湖內區", active: true },
    { key: "Qieding", label: "茄萣區", active: true },
    { key: "Yong’an", label: "永安區", active: true },
    { key: "Mituo", label: "彌陀區", active: true },
    { key: "Ziguan", label: "梓官區", active: true },
    { key: "Qishan", label: "旗山區", active: true },
    { key: "Meinong", label: "美濃區", active: true },
    { key: "Liugui", label: "六龜區", active: true },
    { key: "Jiaxian", label: "甲仙區", active: true },
    { key: "Shanlin", label: "杉林區", active: true },
    { key: "Neimen", label: "內門區", active: true },
    { key: "Maolin", label: "茂林區", active: true },
    { key: "Taoyuan", label: "桃源區", active: true },
    { key: "Namaxia", label: "那瑪夏區", active: true },
  ],
  IUH: [
    { key: "PingtungCity", label: "屏東市", active: true },
    { key: "Chaozhou", label: "潮州鎮", active: true },
    { key: "Donggang", label: "東港鎮", active: true },
    { key: "Hengchun", label: "恆春鎮", active: true },
    { key: "Wandan", label: "萬丹鄉", active: true },
    { key: "Changzhi", label: "長治鄉", active: true },
    { key: "Linluo", label: "麟洛鄉", active: true },
    { key: "Jiuru", label: "九如鄉", active: true },
    { key: "Ligang", label: "里港鄉", active: true },
    { key: "Yanpu", label: "鹽埔鄉", active: true },
    { key: "Gaoshu", label: "高樹鄉", active: true },
    { key: "Wanluan", label: "萬巒鄉", active: true },
    { key: "Neipu", label: "內埔鄉", active: true },
    { key: "Zhutian", label: "竹田鄉", active: true },
    { key: "Xinpi", label: "新埤鄉", active: true },
    { key: "Fangliao", label: "枋寮鄉", active: true },
    { key: "Xinyuan", label: "新園鄉", active: true },
    { key: "Kanding", label: "崁頂鄉", active: true },
    { key: "Linbian", label: "林邊鄉", active: true },
    { key: "Nanzhou", label: "南州鄉", active: true },
    { key: "Jiadong", label: "佳冬鄉", active: true },
    { key: "Liuqiu", label: "琉球鄉", active: true },
    { key: "Checheng", label: "車城鄉", active: true },
    { key: "Manzhou", label: "滿州鄉", active: true },
    { key: "Fangshan", label: "枋山鄉", active: true },
    { key: "Sandimen", label: "三地門鄉", active: true },
    { key: "Wutai", label: "霧臺鄉", active: true },
    { key: "Majia", label: "瑪家鄉", active: true },
    { key: "Taiwu", label: "泰武鄉", active: true },
    { key: "Laiyi", label: "來義鄉", active: true },
    { key: "Chunri", label: "春日鄉", active: true },
    { key: "Shizi", label: "獅子鄉", active: true },
    { key: "Mudan", label: "牡丹鄉", active: true },
  ],
  ILN: [
    { key: "YilanCity", label: "宜蘭市", active: true },
    { key: "Luodong", label: "羅東鎮", active: true },
    { key: "Su’ao", label: "蘇澳鎮", active: true },
    { key: "Toucheng", label: "頭城鎮", active: true },
    { key: "Jiaoxi", label: "礁溪鄉", active: true },
    { key: "Zhuangwei", label: "壯圍鄉", active: true },
    { key: "Yuanshan", label: "員山鄉", active: true },
    { key: "Dongshan", label: "冬山鄉", active: true },
    { key: "Wujie", label: "五結鄉", active: true },
    { key: "Sanxing", label: "三星鄉", active: true },
    { key: "Datong", label: "大同鄉", active: true },
    { key: "Nan’ao", label: "南澳鄉", active: true },
  ],
  HWA: [
    { key: "HualienCity", label: "花蓮市", active: true },
    { key: "Fenglin", label: "鳳林鎮", active: true },
    { key: "Yuli", label: "玉里鎮", active: true },
    { key: "Xincheng", label: "新城鄉", active: true },
    { key: "Ji’an", label: "吉安鄉", active: true },
    { key: "Shoufeng", label: "壽豐鄉", active: true },
    { key: "Guangfu", label: "光復鄉", active: true },
    { key: "Fengbin", label: "豐濱鄉", active: true },
    { key: "Ruisui", label: "瑞穗鄉", active: true },
    { key: "Fuli", label: "富里鄉", active: true },
    { key: "Xiulin", label: "秀林鄉", active: true },
    { key: "Wanrong", label: "萬榮鄉", active: true },
    { key: "Zhuoxi", label: "卓溪鄉", active: true },
  ],
  TTT: [
    { key: "TaitungCity", label: "臺東市", active: true },
    { key: "Chenggong", label: "成功鎮", active: true },
    { key: "Guanshan", label: "關山鎮", active: true },
    { key: "Beinan", label: "卑南鄉", active: true },
    { key: "Luye", label: "鹿野鄉", active: true },
    { key: "Chishang", label: "池上鄉", active: true },
    { key: "Donghe", label: "東河鄉", active: true },
    { key: "Changbin", label: "長濱鄉", active: true },
    { key: "Taimali", label: "太麻里鄉", active: true },
    { key: "Dawu", label: "大武鄉", active: true },
    { key: "Lüdao", label: "綠島鄉", active: true },
    { key: "Haiduan", label: "海端鄉", active: true },
    { key: "Yanping", label: "延平鄉", active: true },
    { key: "Jinfeng", label: "金峰鄉", active: true },
    { key: "Daren", label: "達仁鄉", active: true },
    { key: "Lanyu", label: "蘭嶼鄉", active: true },
  ],
  PEH: [
    { key: "MagongCity", label: "馬公市", active: true },
    { key: "Huxi", label: "湖西鄉", active: true },
    { key: "Baisha", label: "白沙鄉", active: true },
    { key: "Xiyu", label: "西嶼鄉", active: true },
    { key: "Wang’an", label: "望安鄉", active: true },
    { key: "Qimei", label: "七美鄉", active: true },
  ],
  KMN: [
    { key: "Jincheng", label: "金城鎮", active: true },
    { key: "Jinhu", label: "金湖鎮", active: true },
    { key: "Jinsha", label: "金沙鎮", active: true },
    { key: "Jinning", label: "金寧鄉", active: true },
    { key: "Lieyu", label: "烈嶼鄉", active: true },
    { key: "Wuqiu", label: "烏坵鄉", active: true },
  ],
  LNN: [
    { key: "Nangan", label: "南竿鄉", active: true },
    { key: "Beigan", label: "北竿鄉", active: true },
    { key: "Juguang", label: "莒光鄉", active: true },
    { key: "Dongyin", label: "東引鄉", active: true },
  ],
};
