import React from "react";
import Modal from "../Modal/Modal";

import { ReactComponent as LoaderIcon } from "../../../assets/materials/hun-com-loading-img-2.svg";

const Loader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
	if (!isLoading) return null;

	return (
		<Modal>
			<LoaderIcon className="loader" />
		</Modal>
	);
};
export default Loader;
