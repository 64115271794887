import { useCallback, useEffect, useState } from "react";
import { OrderInfo, PrePayInfo } from "../types/responseTypes";
import Api from "../api/Api";

declare global {
  namespace NodeJS {
    export interface ProcessEnv {
      REACT_APP_ENV: "production" | "development";
    }
  }
}

interface PromiseHandler {
  <C extends (...arg: any[]) => Promise<any>>(callback: C): (
    ...arg: Parameters<C>
  ) => Promise<any>;
}

const useOrderInfo = (shortId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [orderInfo, setOrderInfo] = useState<OrderInfo | null>(null);
  const [prePayInfo, setPrePayInfo] = useState<PrePayInfo | null>(null);

  useEffect(() => {
    if (shortId) {
      informationHandler();
    }
    // eslint-disable-next-line
  }, [shortId]);

  useEffect(() => {
    if (!orderInfo) return;
    document.title = orderInfo.store.group.name;
  }, [orderInfo]);

  const promiseHandler: PromiseHandler =
    (cb) =>
    async (...arg) => {
      setIsLoading(true);
      try {
        const result = await cb(...arg);
        setIsLoading(false);
        return result;
      } catch {
        setIsLoading(false);
      }
    };

  const getOrderInfo = useCallback(async () => {
    const { data } = await Api.get<OrderInfo>(`/api/v3/p/orders/${shortId}`);
    setOrderInfo(data);
    return data;
  }, [shortId]);

  const getPrePayInfo = async () => {
    const { data } = await Api.get<PrePayInfo>(
      `/api/v3/p/orders/${shortId}/prePayInfo`
    );
    setPrePayInfo(data);
  };

  const informationHandler = promiseHandler(async () => {
    try {
      const { prePayStatus } = await getOrderInfo();
      if (prePayStatus) {
        await getPrePayInfo();
      }
    } catch (error) {
      setIsError(true);
    }
  });

  const putCancelOrderInfo = promiseHandler(async () => {
    try {
      const { data } = await Api.put(`/api/v3/p/orders/${shortId}/cancel`);
      setOrderInfo(data);
    } catch (error: any) {
      alert(JSON.stringify(error.response, null, 2));
    }
  });

  const putToReportPrePayPayed = promiseHandler(async (payAccount: string) => {
    try {
      await Api.put(`/api/v3/p/orders/${shortId}/prepay`, { payAccount });
      window.location.reload();
    } catch (error: any) {
      alert("回報失敗，請稍後再試，或聯繫店家。");
      throw new Error(error);
    }
  });

  return {
    orderInfo,
    prePayInfo,
    isLoading,
    getOrderInfo,
    putCancelOrderInfo,
    putToReportPrePayPayed,
    isError,
  };
};

export default useOrderInfo;
