import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

import "./css/index.css";
import "./css/style.scss";
import "./css/component.scss";
import "./css/layout.scss";
import "./css/global.scss";

ReactDOM.render(
	<StrictMode>
		<App />
	</StrictMode>,
	document.getElementById("root")
);
