import React, { ComponentProps, PropsWithChildren, ReactElement } from "react";
import { isNotSet } from "../../../tools/format.checker";

type Props<T> = {
  as?: T;
} & TagsProps<T>;

type TagsProps<Tag> = Tag extends HTMLTags
  ? JSX.IntrinsicElements[Tag]
  : Tag extends React.ComponentType
  ? ComponentProps<Tag>
  : never;

type TagType = HTMLTags | React.ComponentType | null;

interface TagComponent extends FCWithoutComponent {
  <T extends TagType>(
    props: PropsWithChildren<Props<T>>,
    context?: any
  ): ReactElement<any, any> | null;
}

const Tag: TagComponent = ({ as = "div", ...props }) => {
  if (isNotSet(as)) return null;

  //@ts-ignore
  return React.createElement(as, { ...props });
};

export default Tag;

export type TagComponentProps = ComponentProps<typeof Tag>;
