import React from "react";
import { OrderStatusTypeKey } from "../../types/static.option.types";

type OwnProps = {
  status: OrderStatusTypeKey;
};

const OrderStatus: React.FC<OwnProps> = ({ status }) => {
  const statusText = (): string => {
    switch (status) {
      case "CHECKED_IN":
        return "訂單已完成";
      case "CONSUMER_CANCEL":
      case "UNPAID_CANCEL":
      case "CANCEL":
        return "您已取消本次預約";
      case "NO_SHOW":
        return "您缺席本次預約";
      default:
        return "";
    }
  };
  return (
    <div
      className={`label__status label__status--${status.toLowerCase()} tiny`}
    >
      {statusText()}
    </div>
  );
};

export default OrderStatus;
