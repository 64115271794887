import React from "react";
import Modal from "../../General/Modal/Modal";
import classNames from "classnames";
import Flexbox from "../../General/Flexbox/Flexbox";
import { ReactComponent as Times } from "../../../assets/icons/times-light.svg";

interface Props {
  open: boolean;
  close: () => void;
}

const UnableCancelPrompt: React.FC<Props> = ({open, close}) => {
    if (!open) return null;

    return (
        <Modal className={classNames("px-8")}>
            <div
                className={classNames(
                    "max-w-[16rem] w-full",
                    "relative",
                    "bg-grey-5",
                    "rounded-sm",
                    "overflow-hidden"
                )}
            >
                <button
                    type={"button"}
                    onClick={close}
                    className={classNames(
                        "absolute top-2 right-2",
                        "flex justify-center items-center",
                        "w-11 h-11"
                    )}
                >
                    <Times
                        className={classNames("w-6 h-6", "block", "text-primaryDark")}
                    />
                </button>
                <Flexbox className={classNames("px-[3rem] pt-[4.25rem] pb-[2.625rem]")}>
                    <p className={classNames("text-grey-1 text-center")}>
                        此預約無法線上取消， 請直接聯繫店家。
                    </p>
                </Flexbox>
                <button
                    type={"button"}
                    onClick={close}
                    className={classNames(
                        "w-full",
                        "h-12",
                        "bg-white",
                        "text-fz4 text-grey-1 font-semibold"
                    )}
                >
                    我知道了
                </button>
            </div>
        </Modal>
    );
};

export default UnableCancelPrompt;
