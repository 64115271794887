import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import Modal from "../General/Modal/Modal";
import ConditionalFragment from "../common/ConditionalFragment";
import moment from "moment";
import { useQuery } from "../../hooks/useQuery";
import { useParams } from "react-router-dom";
import Api from "../../api/Api";
import { OrderInfo } from "../../types/responseTypes";
import { ReactComponent as Check } from "../../assets/icons/check-regular.svg";

type Props = {
  orderInfo: OrderInfo | null;
  reload: () => Promise<OrderInfo>;
  onCancelClick: () => void;
};

function isOrderWaitingConfirmation(orderInfo: OrderInfo | null) {
  if (orderInfo?.startAt) {
    const now = moment().endOf("day");
    const appointmentTime = moment(orderInfo.startAt).endOf("day");

    const withinTwoDays =
      now.isBefore(appointmentTime) && appointmentTime.diff(now, "day") <= 2;
    const isWaiting = orderInfo.status === "WAITING";

    // when checkReserve is null, it means user has not confirmed this appointment yet.
    return withinTwoDays && isWaiting && orderInfo.checkReserve === null;
  }
  return false;
}

const ReminderModal: React.FC<Props> = ({
  orderInfo,
  reload,
  // onCancelClick,
}) => {
  const [showReminder, setShowReminder] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const query = useQuery();
  // If user confirms the appointment on LINE app, user will be redirected to this page with query string: action=confirmReserve.
  const isConfirmedByLine = query.get("action") === "confirmReserve";
  const { oid: orderId } = useParams<{ oid: string }>();

  const confirmAndReload = useCallback(async () => {
    await Api.put(`/api/v3/p/orders/${orderId}/confirmOrderReserve`);
    await reload();
  }, [orderId, reload]);

  useEffect(() => {
    if (isOrderWaitingConfirmation(orderInfo)) {
      if (isConfirmedByLine) {
        confirmAndReload()
          .then(() => {
            setShowConfirmation(true);
          })
          .catch(() => {
            alert("請重新整理或聯繫店家。");
          });
      } else {
        setShowReminder(true);
      }
    }
  }, [confirmAndReload, isConfirmedByLine, orderInfo]);

  const confirmAppointment = useCallback(async () => {
    try {
      await confirmAndReload();
      setShowReminder(false);
      setShowConfirmation(true);
    } catch {
      alert("回報失敗，請稍後再試或聯繫店家。");
    }
  }, [confirmAndReload]);

  const onCloseConfirmModal = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  // const onCancelAndCloseReminderModal = useCallback(() => {
  //   onCancelClick();
  //   setShowReminder(false);
  // }, [onCancelClick]);
  const closeReminderModal = useCallback(() => {
    setShowReminder(false);
  }, []);

  return (
    <ConditionalFragment condition={showReminder || showConfirmation}>
      <Modal>
        <div
          className={classNames(
            "flex flex-col items-center",
            "w-64",
            "rounded-sm",
            "overflow-hidden"
          )}
        >
          <div
            className={classNames(
              "flex flex-col items-center",
              "w-full",
              "py-6",
              "text-grey-1",
              "bg-grey-5"
            )}
          >
            <ConditionalFragment condition={showReminder}>
              <h3 className={classNames("mb-2", "text-fz3 font-semibold")}>
                提醒確認
              </h3>
              <p className={classNames("text-base text-center")}>
                您的預約即將到來，
                <br />
                提醒您請務必準時出席。
              </p>
            </ConditionalFragment>

            <ConditionalFragment condition={showConfirmation}>
              <Check
                className={classNames(
                  "w-8 h-8",
                  "p-1.5",
                  "mb-4",
                  "text-white",
                  "bg-green-2",
                  "rounded-full"
                )}
              />
              <h3 className={classNames("mb-2", "text-fz3 font-semibold")}>
                謝謝回覆
              </h3>
              <p className={classNames("text-base")}>期待您的到來</p>
            </ConditionalFragment>
          </div>
          <div className={classNames("flex", "w-full", "bg-white")}>
            <ConditionalFragment condition={showReminder}>
              <button
                onClick={closeReminderModal}
                className={classNames(
                  "flex-1",
                  "py-2.5",
                  "text-[#48CFAD] text-center font-semibold",
                  "hover:text-opacity-80"
                )}
              >
                檢視預約
              </button>
              <button
                onClick={confirmAppointment}
                className={classNames(
                  "flex-1",
                  "py-2.5",
                  "text-blue",
                  "hover:text-opacity-80"
                )}
              >
                我會出席
              </button>
            </ConditionalFragment>

            <ConditionalFragment condition={showConfirmation}>
              <button
                onClick={onCloseConfirmModal}
                className={classNames(
                  "flex-1",
                  "py-2.5",
                  "text-grey-2",
                  "hover:text-opacity-80"
                )}
              >
                關閉
              </button>
            </ConditionalFragment>
          </div>
        </div>
      </Modal>
    </ConditionalFragment>
  );
};

export default ReminderModal;
