import {
	cityType,
	countryType,
	districtType,
	Key,
} from "../types/static.options";
import {
	CityCodeTypes,
	CountryCodeTypes,
	DistrictCodeTypes,
} from "./../types/static.option.types";

export const transferCountryCode = (countryCode: CountryCodeTypes): string => {
	let country: string = "";
	countryType.forEach(({ key, label }) => {
		country = key === countryCode ? label : country;
	});

	return country;
};

export const transferCityCode = (
	countryCode: CountryCodeTypes,
	cityCode: CityCodeTypes
): string => {
	let city: string = "";
	cityType[countryCode].forEach(({ key, label }) => {
		city = key === cityCode ? label : city;
	});
	return city;
};

export const transferDistrictCode = (
	cityCode: CityCodeTypes,
	districtCode: string
): string => {
	let district: string = "";
	districtType[cityCode].forEach((districtObj: Key) => {
		const { key, label } = districtObj;
		district = key === districtCode ? label : district;
	});
	return district;
};

export const exportAddress: (
	countryCode?: CountryCodeTypes | '',
	cityCode?: CityCodeTypes,
	districtCode?: DistrictCodeTypes
) => { country: string; city: string; district: string } = (
	countryCode,
	cityCode,
	districtCode
) => {
	let country = "",
		city = "",
		district = "";

	if (countryCode) {
		country = transferCountryCode(countryCode);
		if (cityCode) {
			city = transferCityCode(countryCode, cityCode);
			if (districtCode) {
				district = transferDistrictCode(cityCode, districtCode);
			}
		}
	}

	return { country, city, district };
};
