import { Moment } from "moment";

export const isSet = <O>(whatever: O | null | undefined): whatever is O => {
	return whatever !== null && whatever !== undefined;
};

export const isNotSet = <O>(whatever: O | null | undefined): whatever is (null | undefined) => {
	return !isSet(whatever);
};

export const isEmptyStr = (str?: string): boolean => {
	return isSet(str) && str!.length === 0;
};

export const isNonEmptyStr = (str: string): boolean => {
	return !isEmptyStr(str);
};

export const isEmptyAry = (ary: any): boolean => {
	if (isNotSet(ary)) throw new Error(`ary not settle.`);

	return Array.isArray(ary) && ary.length === 0;
};

export const isNotEmptyAry = (ary: any[]): boolean => !isEmptyAry(ary);

export const isFile = (file: File) => {
	const getType = {};

	return file && getType.toString.call(file) === "[object File]";
};

export const isBlob = (file: Blob) => {
	const getType = {};

	return file && getType.toString.call(file) === "[object Blob]";
};

export const isSameDay = (day1: Moment, day2: Moment) =>
	day1.format("YYYY/MM/DD") === day2.format("YYYY/MM/DD");

export const isNegative = (num: number): boolean => num < 0;

export const isPositive = (num: number): boolean => num > 0;
