import classNames from "classnames";
import React, { PropsWithChildren, ReactElement, ReactHTML } from "react";

type FontSize =
  | "fz1"
  | "fz2"
  | "fz3"
  | "fz4"
  | "fz5"
  | "fz6"
  | "primaryHeading"
  | "secondaryHeading"
  | "button"
  | "body"
  | "tiny";

interface Props<T> {
  as?: T;
  unresponsive?: boolean;
  fontSize?: FontSize;
  withoutLeading?: boolean;
}

type TagsKey = keyof ReactHTML;

type TagsProps<Tag extends TagsKey> = NonNullable<
  Parameters<ReactHTML[Tag]>[0]
>;

interface FontsComponent extends FCWithoutComponent {
  <T extends TagsKey>(
    props: PropsWithChildren<Props<T> & TagsProps<T>>,
    context?: any
  ): ReactElement<any, any> | null;
}

/**
 * @param props[fontSize][fz1] // 28px large:32px
 * @param props[fontSize][fz2] // 24px large:28px
 * @param props[fontSize][fz3] // 20px large:22px
 * @param props[fontSize][fz4] // 16px large:18px
 * @param props[fontSize][fz5] // 14px large:16px
 * @param as
 * @param className
 * @param fontSize
 * @param unresponsive
 * @param withoutLeading
 * @param children
 * @param props[fontSize][fz6] // 12px large:14px
 * @param props[fontSize][primaryHeading] // 28px large:32px
 * @param props[fontSize][secondaryHeading] // 24px large:28px
 * @param props[fontSize][button] // 16px large:16px
 * @param props[fontSize][body] // 14px large:16px
 * @param props[fontSize][tiny] // 12px large:14px
 */
const Fonts: FontsComponent = ({
  as = "p",
  className,
  fontSize = "body",
  unresponsive,
  withoutLeading,
  children,
  ...props
}) => {
  const Tags = as ? as : "p";

  const fontStyles = fontSizeType[fontSize];

  return (
    <Tags
      className={classNames(className, [
        fontStyles.mobile,
        !unresponsive && [
          fontStyles.laptop,
          !withoutLeading && fontStyles.leadingLaptop,
        ],
        fontStyles.fontWeight,
        !withoutLeading && fontStyles.leading,
      ])}
      {...props}
    >
      {children}
    </Tags>
  );
};

export default Fonts;

const fontSizeType: Record<
  FontSize,
  {
    mobile: string;
    laptop: string;
    leading?: string;
    leadingLaptop?: string;
    fontWeight?: string;
  }
> = {
  fz1: {
    mobile: "text-fz1",
    laptop: "lg:text-fz1Lg",
  },
  fz2: {
    mobile: "text-fz2",
    laptop: "lg:text-fz2Lg",
  },
  fz3: {
    mobile: "text-fz3",
    laptop: "lg:text-fz3Lg",
  },
  fz4: {
    mobile: "text-fz4",
    laptop: "lg:text-fz4Lg",
  },
  fz5: {
    mobile: "text-fz5",
    laptop: "lg:text-fz5Lg",
  },
  fz6: {
    mobile: "text-fz6",
    laptop: "lg:text-fz6Lg",
  },
  primaryHeading: {
    mobile: "text-fz1",
    laptop: "lg:text-fz1Lg",
    leading: "leading-7",
    leadingLaptop: "lg:leading-8",
    fontWeight: "font-bold",
  },
  secondaryHeading: {
    mobile: "text-fz2",
    laptop: "lg:text-fz2Lg",
    leading: "leading-6",
    leadingLaptop: "lg:leading-7",
    fontWeight: "font-bold",
  },
  button: {
    mobile: "text-fz4",
    laptop: "lg:text-fz5Lg",
    leading: "leading-4",
    leadingLaptop: "lg:leading-4",
  },
  body: {
    mobile: "text-fz5",
    laptop: "lg:text-fz5Lg",
    leading: "leading-3.5",
    leadingLaptop: "lg:leading-4",
  },
  tiny: {
    mobile: "text-fz6",
    laptop: "lg:text-fz6Lg",
    leading: "leading-3",
    leadingLaptop: "lg:leading-3",
  },
};
