import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from "./Main";
import NoMatch from "./General/NoMatch/NoMatch";

const initWindowSize = () => {
	let vh = window.innerHeight;
	let vw = window.innerWidth;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	document.documentElement.style.setProperty("--vw", `${vw}px`);
};

const App: React.FC = () => {
	useEffect(() => {
		initWindowSize();
		window.addEventListener("resize", initWindowSize);

		return () => {
			window.removeEventListener("resize", initWindowSize);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<BrowserRouter>
			<main>
				<Switch>
					<Route path="/:oid" component={Main} />
					<Route path="*" component={NoMatch} />
				</Switch>
			</main>
			<footer className="footer">
				<p className="footer__text footer__text--1 tiny">
					powered by <span className="footer__highlight">hunger</span>
				</p>
				<p className="footer__text footer__text--2 tiny">v3.0.0</p>
			</footer>
		</BrowserRouter>
	);
};

export default App;
