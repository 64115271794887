import moment, { Moment } from "moment";

type MomentParams = OverloadedArguments<typeof moment>;

export const hungerMoment = <T extends MomentParams>(...any: T): Moment => {
	const args: any = [...any];
	return moment(...args)
		.utc()
		.utcOffset(8)
		.clone();
};
