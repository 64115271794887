import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

type Props = {
	children: React.ReactChild | React.ReactChild[];
	zIndex?: number;
};

const Modal: React.FC<Props & ReactHTMLElementProps<HTMLDivElement>> = ({
	children,
	className,
	style,
	zIndex = 100,
	...props
}) => {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	const settingWindow = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};

	useEffect(() => {
		settingWindow();
		window.addEventListener("resize", settingWindow);

		return () => {
			window.removeEventListener("resize", settingWindow);
		};
	}, []);

	return ReactDOM.createPortal(
		<div
			className={classNames(
				className,
				"fixed",
				"top-0",
				"left-0",
				"bg-black",
				"bg-opacity-65",
				"flex",
				"justify-center",
				"items-center"
			)}
			style={{ width, height, zIndex, ...style }}
			{...props}
		>
			{children}
		</div>,
		document.querySelector("body")!
	);
};

export default Modal;
